<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<exportModal class="ui-exportBrn" v-permission="['pc_warn_loss_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon><Icon icon="UploadOutlined"/></template>
							导出</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" v-permission="['pc_warn_loss_export']" style="margin-right: 16px;" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon><Icon icon="LogoutOutlined"/></template>
							导出全部</a-button>
					</exportModal>
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="盘点任务名称" class="ui-form__item">
						<a-input v-model:value="formModal.taskName"></a-input>
					</a-form-item>
					<a-form-item label="是否已处理" class="ui-form__item">
						<a-select  allow-clear v-model:value="formModal.isAudit" style="width: 190px;">
							<a-select-option :value="-1">全部</a-select-option>
							<a-select-option :value="1">是</a-select-option>
							<a-select-option :value="0">否</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="预警时间段" class="ui-form__item">
						<a-range-picker :value="clearTime" @change="onChangeFun" showTime></a-range-picker>
					</a-form-item>
					<a-form-item class="ui-form__item">
						<div style="text-align: center;">
							<a-button @click="onReset">重置</a-button>
							<a-button type="primary" @click="onSearch">搜索</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox :value="'taskName'">盘点任务名称</a-checkbox>
							<a-checkbox :value="'createTime'">预警时间</a-checkbox>
							<a-checkbox :value="'state'">是否已处理</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'status'">
							<div v-if="record.status === 1">是</div>
							<div v-else>否</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_warn_loss_result']" type="link" size="small" @click="onResult(record)">盘亏管理</a-button>
						</template>
					</template>
				</c-Table>
				
				<!-- <a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'status'">
							<div v-if="record.status === 1">是</div>
							<div v-else>否</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_warn_loss_result']" type="link" size="small" @click="onResult(record)">盘亏管理</a-button>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		<resultModal ref="resultModalRef"></resultModal>
	</div>
</template>

<script>
	import { getAdminLossWarnList } from '@/service/modules/warning.js';
	import { Icon } from '@/components/icon/icon.js';
	import resultModal from '@/views/inventory/components/resultModal.vue';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: { Icon, resultModal, cTable, exportModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				searchData: {},
				formModal: {
					type: 1 // 盘亏
				},
				type: 'WarningStrategy', // 导出类型
				role: 0, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				clearTime: [], // 存储时间 用来清空重置
				getDataFun: getAdminLossWarnList,
				list: [],
				sortList:[],
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index'
				}, {
					title: '盘点任务名称',
					align: 'center',
					dataIndex: "taskName"
				}, {
					title: '预警提示',
					align: 'center',
					dataIndex: "title"
				}, {
					title: '是否已处理',
					align: 'center',
					key: 'status',
					dataIndex: "status"
				}, {
					title: '预警时间',
					align: 'center',
					key: "time"
				}, {
					title: '操作',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 120
				}],
			}
		},
		created() {
			this.onSearch();
		},
		methods: {
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getAdminLossWarnList({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			type: 1, // 盘亏
			// 			...this.searchData
			// 		})
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 			console.log("waring",this.list)
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	} 
			// },
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {}
				this.clearTime = []
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));	
				// this.pagination.current = 1;
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onResult(item) {
				if(item.status === 1) {
					this.$refs.resultModalRef.open(item,"预警");
				} else if(item.status === 0) {
					this.$router.push({
						name: 'inventoryLoss'
					})
				}
			},
			onChangeFun(date, dateString) { // 时间变化的回调
			  this.clearTime = date
			  let starDate = new Date(dateString[0])
			  let endDate = new Date(dateString[1])
			  // console.log(starDate.valueOf(), endDate.valueOf());
			  this.formModal.startTime = starDate.valueOf()/1000
			  this.formModal.endTime = endDate.valueOf()/1000
			},
			onSortClick(type){
				let list = [];
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});
				this.formModal.sort = list;
				console.log(this.formModal);
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>